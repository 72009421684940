<template>
  <div class="page-main">
    <!-- 表格操作条 -->
    <vxe-toolbar ref="refToolbar" size="mini" export print custom :loading="loading">
      <template #tools>
        <span class="label">日期：</span>
        <e-date-picker type="daterange" :value.sync="pickerDate" @change="searchListData" />
      </template>
    </vxe-toolbar>

    <!-- 表格 -->
    <vxe-grid
      ref="refTable"
      size="mini"
      border
      show-overflow
      highlight-hover-row
      :loading="loading"
      :max-height="this.$util.getViewHeight() - 150"
      :data="tableDataMain"
      :columns="tableColumn"
      :mouse-config="{ selected: false }"
      :export-config="{ type: ['xlsx'], types: ['xlsx'] }"
      :edit-config="{ showIcon: false, trigger: 'click', mode: 'cell' }"
      :keyboard-config="{
        enterToTab: true,
        isArrow: true,
        isDel: false,
        isEnter: true,
        isTab: true,
        isEdit: false
      }"
    >
      <!-- slot_pager -->
      <template #pager>
        <div></div>
        <vxe-pager v-bind="pageOptions" @page-change="pageChange" />
      </template>
    </vxe-grid>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: true,

      tableDataMain: [],
      tableColumn: [
        {
          field: 'id',
          width: 100,
          title: '编号'
        },
        {
          field: 'created',
          width: 160,
          title: '日期'
        },
        {
          field: 'nick_name',
          width: 100,
          title: '用户名称'
        },
        {
          field: 'user_name',
          width: 120,
          title: '账户'
        },
        {
          field: 'ip',
          width: 120,
          title: 'IP地址'
        },
        {
          field: 'context',
          title: '操作内容',
          editRender: { name: 'ElInput', props: { readonly: true, controls: false } }
        },
        {
          field: 'action',
          width: 160,
          title: '访问地址'
        }
      ],
      pageOptions: {
        ...this.$constant.page
      },
      filterData: {},
      sortData: {},
      pickerDate: [this.$util.getDate().sevenDay, this.$util.getDate().today] // 查询年限 月份
    }
  },
  methods: {
    // 搜索列表数据
    searchListData() {
      this.pageOptions.currentPage = 1
      this.getListData()
    },
    // 获取列表数据
    getListData() {
      this.loading = true
      this.$api({
        method: 'post',
        url: '/admin/logger/getList',
        data: {
          currentPage: this.pageOptions.currentPage,
          pageSize: this.pageOptions.pageSize,
          pickerDate: this.pickerDate,
          sortData: this.sortData,
          filterData: this.filterData
        }
      })
        .then((res) => {
          if (res.status === 200) {
            this.tableDataMain = res.data.list
            this.pageOptions.pageSize = res.data.pageSize
            this.pageOptions.total = res.data.totalRow
            this.pageOptions.currentPage = res.data.pageNumber
          }
        })
        .catch(() => {})
        .then(() => {
          this.loading = false
        })
    },
    // 分页切换
    pageChange({ currentPage, pageSize }) {
      this.pageOptions.currentPage = currentPage
      this.pageOptions.pageSize = pageSize
      this.getListData()
    }
  },
  created() {
    this.$nextTick(() => {
      this.getListData()
      this.$refs.refTable.connect(this.$refs.refToolbar) // 手动将表格和工具栏进行关联
    })
  }
}
</script>
<style lang="less" scoped>
.page-main {
}
</style>
